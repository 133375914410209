<template>
    <div>
          <v-btn   color="primary" class="ma-2 white--text"  @click="showAddIndustryType">
             {{ $t("IndustryType.add") }} <v-icon right dark> mdi-plus</v-icon>
        </v-btn>
          <v-container color="primary">
              <v-card
                    icon="clipboard-text"
                    title="Industry Type"
                    class="px-5 py-10">

                     <v-card-title>
                    <v-row>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details>
                        </v-text-field>
                    </v-row>
                    <v-row>
                        <v-progress-linear
                            :active="loading"
                            color="green"
                            :indeterminate="loading"
                            absolute
                            bottom
                            height="15">
                            Loading...
                        </v-progress-linear>`
                    </v-row>
                </v-card-title>

                    <v-data-table
                        dense
                        :headers="fields"
                        :items="items"
                        :search="search"
                        class="elevation-1">
                            <template  v-slot:[`header.economicSectorName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                            <template  v-slot:[`header.englishName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                            <template  v-slot:[`header.arabicName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                             <template  v-slot:[`header.Action`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                              <template v-slot:top>
                                  <v-dialog
                                    v-model="Editdialog"
                                    max-width="800px">
                                        <v-card>
                                             <v-card-title>
                                                <span class="text-h5">Edit Industry Type</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-form ref="form" v-model="valid" lazy-validation>
                                                    <v-container>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-select
                                                                    v-model="editedItem.economicSectorId"
                                                                    :items="options"
                                                                    :rules="[rules.required]"
                                                                    label="Economic Sector"
                                                                    required
                                                                    ></v-select>
                                                            </v-col>
                                                         </v-row>
                                                          <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.englishName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="English Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.arabicName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="Arabic Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                    </v-container>
                                                </v-form>
                                            </v-card-text>
                                             <v-card-actions>
                                                <v-spacer></v-spacer>
                                                 <v-btn color="green darken-1" text @click="close"> Cancel</v-btn>
                                                 <v-btn color="green darken-1" :disabled="!valid" text @click="validate" >Save</v-btn>
                                             </v-card-actions>
                                        </v-card>
                                  </v-dialog>
                              </template>
                              <template v-slot:[`item.Action`]="{ item }">
                                    <v-icon small class="mr-2"  @click="editItem(item)" >
                                        mdi-pencil
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="red" v-if="item.status==0" >
                                        mdi-brightness-1
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="green" v-if="item.status==1" >
                                        mdi-brightness-1
                                    </v-icon>
                                    <v-icon small class="mr-2"  @click="approveDeleteConfirm(item)" color="red"  >
                                        mdi-delete-empty
                                    </v-icon>
                                </template>
                    </v-data-table>
              </v-card>
          </v-container>
         <v-dialog v-model="approveReqDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="updateTypeStatus">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="closeApprove">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>

                <div class="text-center">
                <v-snackbar v-model="snackbar" timeout="3000">
                    {{ alertMsg }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
            </v-snackbar>
        </div>
          <v-dialog
        v-model="addIndustryTypeDialog"
        max-width="800px">
            <v-card>
                 <v-card-title>
                    <span class="text-h5">Add Industry type</span>
                </v-card-title>
                 <v-card-text>
                      <v-form ref="Addform" v-model="valid" lazy-validation>
                           <v-container>
                                <v-row>
                                     <v-col cols="12" sm="6" md="6">
                                            <v-select
                                            v-model="editedItem.economicSector"
                                            :items="options"
                                            :rules="[rules.required]"
                                            label="Economic Sector"
                                            required
                                            ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                            v-model="editedItem.englishName"
                                            :rules="[rules.required]"
                                            required
                                            label="English Name">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                        v-model="editedItem.arabicName"
                                        :rules="[rules.required]"
                                        required
                                        label="Arabic Name">
                                    </v-text-field>
                                </v-col>
                                </v-row>
                           </v-container>
                      </v-form>
                 </v-card-text>
                 <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="close"> Cancel</v-btn>
                    <v-btn color="green darken-1" :disabled="!valid" text @click="validateNew" >Save</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>

         <v-dialog v-model="confirmDeleteDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteIndustryType">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="confirmDeleteDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    data(){
        return{
            approveReqDialog:false,
            confirmDeleteDialog:false,
            search:'',
            loading:false,
            addIndustryTypeDialog:false,
            Editdialog:false,
            valid:false,
             rules: {
                required: value => !!value || 'Required Field',
            },
            editedItem: {
                id:-1,
                economicSector:-1,
                arabicName: '',
                englishName:'',
                status:-1,
            },
            editedIndex:-1,
             alertMsg:'',
            snackbar:false,
            fields: [
                // {value: 'id', text: this.$t("IndustryType.grid.id") },
                {value: 'economicSectorName', text: this.$t("IndustryType.grid.economicSectorName") },
                {value: 'englishName', text: this.$t("IndustryType.grid.englishName") },
                {value: 'arabicName', text: this.$t("IndustryType.grid.arabicName") },
                {value: 'Action', text: this.$t("IndustryType.grid.Action") },
                ],
            items: [],
            options: [],
            selected: null,
            form: {
                englishName: null,
                arabicName:null,
                economicSector:null,
                id:null
            },
            currentEditedType:null
        }
    },
     validations: {
        form: {
            englishName: {
                required,
                minLength: minLength(3)
            },
            arabicName: {
                required,
                minLength: minLength(3)
            },
            economicSector:{
                required
            }
        }
    },
     methods:{
          approveDeleteConfirm(item){
            if(Boolean(item.hasChildren) == false){
                 this.editedItem = item;
                this.editedIndex = this.items.indexOf(item)
                this.confirmDeleteDialog = true
            }
            else{
                this.alertMsg = "Industry type cannot be deleted, it has business sectors"
                this.snackbar = true;
            }
        },
         validateNew(){
              if(this.$refs.Addform.validate())
            {
                this.addIndustryType();
            }
         },
          validate () {
            if(this.$refs.form.validate())
            {
                this.UpdateIndustryType();
            }
         },
          closeApprove(){
            this.approveReqDialog = false;
            this.clear();
        },
         approveRequestConfirm(item){
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)
            this.approveReqDialog = true;
        },
         close () {
            this.Editdialog = false
            this.addIndustryTypeDialog = false;
            this.$nextTick(() => {
                this.clear();
            })
        },
        clear(){
            this.editedIndex = -1;
            this.editedItem.id = -1;
            this.editedItem.economicSector = -1;
            this.editedItem.arabicName = '';
            this.editedItem.englishName = '';
            this.editedItem.status = -1;
        },
         editItem (item) {
            this.clear();
            this.getEconomicSectorOptions();
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.Editdialog = true
        },

         updateTypeStatus(){
            this.loading = true;
            let status = -1;
            this.editedItem.status == 1 ? status = 0 : status = 1;
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/IndustryType/status?Id=${this.editedItem.id}&Status=${status}`,
                }).then(()=>{
                    this.approveReqDialog = false;
                    this.$nextTick(() => {
                                    this.items[this.editedIndex].status = status;
                                    this.alertMsg = "Industry Type updated successfully"
                                    this.snackbar = true;
                                    this.loading = false;
                             })
                });

        },
         getVariant(item,activateType){
            if(Boolean(activateType) == true){
                if(item.status == 1)
                    return 'primary'
                else
                    return 'secondary'
            }
            else{
                if(item.status == 1)
                    return 'secondary'
                else
                    return 'primary'
            }

        },
         getEconomicSectorOptions(){
             this.loading = true;
              this.$axios({
                method: 'get',
                url: `${this.$baseURL}/EconomicSector/active`,
            })
            .then((response)=>{

                this.options = response.data.result.map(val=>({
                    value:val.id,
                    text:val.englishName
                })
                );
                this.loading = false;
            });
         },
        showAddIndustryType(){
            if(this.options.length == 0){
                this.getEconomicSectorOptions();
            }
           this.clear();
           this.addIndustryTypeDialog = true;
        },
        addIndustryType(){

             this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/IndustryType`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName,
                        EconomicSectorId:this.editedItem.economicSector
                    }
                }).then((response)=>{
                     this.$nextTick(() => {
                                this.addIndustryTypeDialog = false;
                                this.items = response.data;
                                this.close()
                                this.alertMsg = "Industry Type added successfully"
                                this.snackbar = true;
                            });

                });

        },
        editIndustryType(item,index){
              if(this.options.length == 0){
                this.getEconomicSectorOptions();
            }
             this.$nextTick(() => {
                this.form.economicSector =
                this.options.filter(o=>o.englishName ==  item.economicSectorName)[0].id
                this.form.englishName = item.englishName;
                this.form.arabicName = item.arabicName;
                this.form.id = item.id;

                this.$bvModal.show('EditIndustryType');
                this.currentEditedSector = index;
            });

        },
        UpdateIndustryType(){
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/IndustryType`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName,
                        EconomicSectorId:this.editedItem.economicSectorId,
                        Id:this.editedItem.id
                    }
                }).then((response)=>{
                    if(response.data == true)
                    {
                        Object.assign(this.items[this.editedIndex], this.editedItem)
                        this.close()
                        this.alertMsg = "Industry type updated successfully"
                        this.snackbar = true;
                    }
                });
        },
        deleteIndustryType(){
            this.$axios({
                    method: 'delete',
                    url: `${this.$baseURL}/IndustryType`,
                    data: {Id:this.editedItem.id}
                    }).then(()=>{
                         this.items .splice(this.editedIndex , 1);
                        this.confirmDeleteDialog = false;
                    });
        },
        onSubmit(){

        },
        onReset(){
            this.form ={
                englishName: null,
                arabicName:null,
                economicSector:null
            };
             this.$nextTick(() => {
                this.$v.$reset();
            });
        },
          validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
    },
     mounted(){
        this.loading = true;
        this.$axios({
                method: 'get',
                url: `${this.$baseURL}/IndustryType`,
            })
            .then((response)=>{
                this.items = response.data;
                 this.loading = false;
            });
    }
}
</script>

<style>

</style>